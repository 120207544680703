export default function Page() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen gap-4 p-2 font-mts">
      <img
        className="w-[190px] h-[40px] min-w-[190px] float-left gtPhone:mb-4 mr-2 "
        src="/images/potato/logo.svg"
        alt="potatoswap"
      />
      <div className="flex flex-col items-center max-w-xl gap-4 p-10 text-center rounded-[10px] border border-[#151027] border-opacity-20">
        <div className="text-3xl font-bold">Access Restricted</div>
        <div className="text-lg">
          Your IP address shows that you&apos;re attempting to access our interface and services, however we are unable
          to offer our interface and services to your location. Sorry for any inconvenience caused.
        </div>
        <div className="text-lg">
          Please be aware that the regions where we currently cannot provide interface and services include, but are not
          limited to: US, Mainland China, Balkans, Belarus, Burma, Burundi, Central Africa Republic, Crimea and
          Sevastopol, Cuba, Democratic Republic of Congo, Ethiopia, Iran, Iraq, Lebanon, Libya, North Korea, Russia,
          Somalia, Sudan, Syria, Ukraine, Venezuela, Yemen, Zimbabwe.
        </div>
      </div>
    </div>
  )
}
